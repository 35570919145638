import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { SmartSaveStatus } from './smart-save-helpers';
import {
  selectSmartSaveDevices,
  selectSmartSaveStatus,
  updateSmartSaveDevices,
  updateSmartSaveStatus,
} from './smartSaveSlice';

export const useSelectSmartSaveOption = () => {
  const smartSaveDevices = useSelector(selectSmartSaveDevices);
  const smartSaveStatus = useSelector(selectSmartSaveStatus);
  const dispatch = useDispatch();

  const handleRemoveAllFromSmartSave = useCallback(() => {
    const result = { smartSaveDevices, smartSaveStatus };
    const updatedSmartSaveDevices = smartSaveDevices.map((device) => ({
      ...device,
      active: false,
    }));
    result.smartSaveDevices = unwrapResult(dispatch(updateSmartSaveDevices(updatedSmartSaveDevices)));
    result.smartSaveStatus = unwrapResult(dispatch(updateSmartSaveStatus(SmartSaveStatus.OFF)));

    return result;
  }, [dispatch, smartSaveDevices, smartSaveStatus]);

  const handleOneMonthTrialForSmartSave = useCallback(() => {
    const result = { smartSaveDevices, smartSaveStatus };
    const hasActiveDevices = smartSaveDevices.some((device) => device.active);

    if (!hasActiveDevices) {
      const updatedSmartSaveDevices = smartSaveDevices.map((device) => ({
        ...device,
        active: true,
      }));
      result.smartSaveDevices = unwrapResult(dispatch(updateSmartSaveDevices(updatedSmartSaveDevices)));
    }
    result.smartSaveStatus = unwrapResult(dispatch(updateSmartSaveStatus(SmartSaveStatus.TRIAL)));

    return result;
  }, [dispatch, smartSaveDevices, smartSaveStatus]);

  const handleTurnOnSmartSave = useCallback(() => {
    const result = { smartSaveDevices, smartSaveStatus };
    const hasActiveDevices = smartSaveDevices.some((device) => device.active);
    if (!hasActiveDevices) {
      const updatedSmartSaveDevices = smartSaveDevices.map((device) => ({
        ...device,
        active: true,
      }));
      result.smartSaveDevices = unwrapResult(dispatch(updateSmartSaveDevices(updatedSmartSaveDevices)));
    }
    result.smartSaveStatus = unwrapResult(dispatch(updateSmartSaveStatus(SmartSaveStatus.ON)));

    return result;
  }, [dispatch, smartSaveDevices, smartSaveStatus]);

  const updateSmartSaveDevicesAndStatus = useCallback(
    (smartSaveStatus: SmartSaveStatus) => {
      const solarSaveStatusToEventHandler = {
        [SmartSaveStatus.ON]: handleTurnOnSmartSave,
        [SmartSaveStatus.TRIAL]: handleOneMonthTrialForSmartSave,
        [SmartSaveStatus.OFF]: handleRemoveAllFromSmartSave,
      };

      return solarSaveStatusToEventHandler[smartSaveStatus]();
    },
    [handleOneMonthTrialForSmartSave, handleRemoveAllFromSmartSave, handleTurnOnSmartSave]
  );

  return {
    handleRemoveAllFromSmartSave,
    handleOneMonthTrialForSmartSave,
    handleTurnOnSmartSave,
    updateSmartSaveDevicesAndStatus,
  };
};
