import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SolarSpongeDevice, SolarSpongeDeviceCreate } from 'clipsal-cortex-types/src/api';

import { RootState } from '../../../app/store';
import { SmartSaveStatus } from './smart-save-helpers';

export type SmartSaveDevice = SolarSpongeDevice | SolarSpongeDeviceCreate;

interface SmartSaveState {
  smartSaveDevices: SmartSaveDevice[];
  smartSaveStatus: SmartSaveStatus;
}

const initialState: SmartSaveState = {
  smartSaveDevices: [],
  smartSaveStatus: SmartSaveStatus.OFF,
};

export const smartSaveSlice = createSlice({
  name: 'smartSave',
  initialState,
  reducers: {
    updateSmartSaveDevices: (state, action: PayloadAction<SmartSaveDevice[]>) => {
      return {
        ...state,
        smartSaveDevices: action.payload,
      };
    },
    updateSmartSaveStatus: (state, action: PayloadAction<SmartSaveStatus>) => {
      return {
        ...state,
        smartSaveStatus: action.payload,
      };
    },
  },
});

export const { updateSmartSaveDevices, updateSmartSaveStatus } = smartSaveSlice.actions;

export const selectSmartSaveDevices = (state: RootState) => {
  return state.smartSave.smartSaveDevices;
};

export const selectSmartSaveStatus = (state: RootState) => {
  return state.smartSave.smartSaveStatus;
};

export default smartSaveSlice.reducer;
